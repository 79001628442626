import './index.less';

import Vue from 'vue';
import Component from 'vue-class-component';
import { IGetZSPointTableGradePage, SaveZSPointTableGradePage, zsPointTableGradeService } from '@/app/apiServices/zsPointTableGrade/public-api';
import { getStepList, notThisCity_stepsList2 } from '@/core/utils/enum/notThisCity';
import { getStepsIcon } from '@/core/utils';
import { hasRegister } from '@/config/message';
import { BaseResultDto } from '@/app/apiServices/baseDto/resultDto';
import storage from '@/core/utils/storage';
import { ParentScore } from '@/app/components';
@Component({
    components: { ParentScore }
})
export default class parentScoreTS extends Vue {
    formModel = new SaveZSPointTableGradePage();
    comData: any = {};    //传递给组件的数据

    stepsList = notThisCity_stepsList2;
    activeSteps = 3;


    getStepsIcon(index: number) {
        return getStepsIcon(index, this.activeSteps);
    }

    // 加载表单数据
    viewLoad(): void {
        const { childId } = storage.GET_RegistrationInfo();
        zsPointTableGradeService.getZSChildBaseInfoPage(childId)
            .then((res: IGetZSPointTableGradePage) => {
                // 可能为 null 不存在数据
                if (res) {
                    this.comData = res;

                    //重新加载进度条
                    this.stepsList = getStepList();
                }
            });
    }

    // 下一步点击
    nextOnClick(): void {
        const { parentScore }: any = this.$refs;
        if (!parentScore.checkFormModel()) {
            this.$toast({
                message: '填写内容存在错误请仔细核对！',
                position: 'top'
            });
            return;
        }

        this.formModel = parentScore.getFormModel();

        const { childId, gradeCode } = storage.GET_RegistrationInfo();
        this.formModel.childId = Number(childId);
        this.formModel.gradeCode = gradeCode;

        // 确定下一步
        const nextFun = () => {
            const input = Object.assign(new SaveZSPointTableGradePage(), this.formModel);
            zsPointTableGradeService.saveZSChildBaseInfoPage(input)
                .then((res: BaseResultDto) => {
                    if (res.verifyResult.success === true) {
                        this.$router.push(`/notThisCity/materialInformation`);
                    }
                    else {
                        if (res.verifyResult.exceptionCode == "GOHOME") {
                            this.$dialog.alert({
                                message: hasRegister,
                            }).then(() => {
                                this.$router.push("/record");
                            });
                        }
                        else {
                            this.$toast({
                                message: res.verifyResult.message,
                                position: 'top'
                            });
                        }
                    }
                })
        }

        this.$dialog
            .confirm({
                message: "请确定已经填完所有项？确定点击“确定”，如未填完点击‘取消’",
            })
            .then(() => {
                nextFun();
            }).catch(() => {
                // on cancel
            });
    }

    mounted(): void {
        this.viewLoad();
    }
}